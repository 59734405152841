import React from "react"
import PropTypes from "prop-types"
import { Router, BrowserRouter, IndexRoute, MemoryRouter } from "react-router-dom";
import App from "./app";
import { createStore, applyMiddleware } from "redux";
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import { Provider } from 'react-redux';
import allReducers from './reducers';
import { createMemoryHistory } from 'history';
import { apiMiddleware } from 'redux-api-middleware';
import { routerMiddleware } from 'react-router-redux'
import gameListener from './game-listener'
import ReactGA from 'react-ga';
import assets from './assets';

ReactGA.initialize('UA-157273139-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const history = createMemoryHistory({ basename: '/hra' });
const historyMiddleware = routerMiddleware(history);

const store = createStore(
    allReducers,
    applyMiddleware(apiMiddleware,thunk,promise,historyMiddleware)
);

gameListener(store);

// history.listen((location, action) => {
//     console.log(`location change ${JSON.stringify(location)}`);
// });

class Main extends React.Component {
    render () {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <App location={this.props.location}/>
                </Router>
            </Provider>
        );
    }
}

Main.propTypes = {};

export default Main;
